import HttpService from '@app/api/http-service';
import { ResponseWithError } from '@app/api/resources/Responses';
import { ObjectOfStrings } from '@app/api/utility-types';

export type VanityPathPageType =
  | 'special_promo'
  | 'release'
  | 'student_splash_partner';

export type VanitiesResponse = {
  type: VanityPathPageType;
  path: string;
};

export const checkForVanityPath = async (
  httpContext: ObjectOfStrings,
  vanityPath: string,
): ResponseWithError<VanitiesResponse> =>
  HttpService(httpContext).get(`/vanities?path=${vanityPath}`);
